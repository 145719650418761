import _export from "../internals/export";
import _arrayIteration from "../internals/array-iteration";
import _addToUnscopables from "../internals/add-to-unscopables";
var $ = _export;
var $find = _arrayIteration.find;
var addToUnscopables = _addToUnscopables;
var FIND = "find";
var SKIPS_HOLES = true; // Shouldn't skip holes

if (FIND in []) Array(1)[FIND](function () {
  SKIPS_HOLES = false;
}); // `Array.prototype.find` method
// https://tc39.es/ecma262/#sec-array.prototype.find

$({
  target: "Array",
  proto: true,
  forced: SKIPS_HOLES
}, {
  find: function find(callbackfn
  /* , that = undefined */
  ) {
    return $find(this, callbackfn, arguments.length > 1 ? arguments[1] : undefined);
  }
}); // https://tc39.es/ecma262/#sec-array.prototype-@@unscopables

addToUnscopables(FIND);
export default {};